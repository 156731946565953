@import "../../styles/variables";

.games {
  display: flex;
  width: 90%;
  max-width: 1280px;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  scroll-margin: 100px;
  &_title {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    > span {
      color: $color-main-white;
      text-align: center;

      /* web/title/title4 */
      font-family: "Open Sans";
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px; /* 123.077% */
    }
    &_subtitle {
      color: var(--brand-90, #d3e6f8);
      text-align: center;

      /* web/body/bold/text2 */
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
  }
  &_list {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
    width: 100%;
    position: relative;
    & > * img {
      width: 240px;
      height: 320px;
      flex-shrink: 0;
      border-radius: 16px;
    }
    @media screen and (max-width: $breakpoint) {
      display: none;
    }
  }
  &_mobile {
    display: none;
    @media screen and (max-width: $breakpoint) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 12px;
      & > img {
        border-radius: 8px;
        background: var(
          --onSurface-primary-dark-primary4,
          rgba(255, 255, 255, 0.04)
        );
        box-shadow: 0px 40px 80px 0px rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(60px);
        display: flex;
        padding: 24px;
        align-items: flex-start;
        gap: 8px;
        width: 40px;
        aspect-ratio: 1 / 1;
      }
    }
  }
}

.swiper {
  width: 100%;
  height: 400px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px !important;
  height: 320px !important;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-wrapper {
  gap: 10px;
}

.swiper-button-prev,
.swiper-button-next {
  color: #f2f2f2 !important;
}

.swiper-navigation {
  --swiper-navigation-top-offset: 1% !important;
}

.swiper-scrollbar {
  --swiper-scrollbar-size: 12px;
  --swiper-scrollbar-bg-color: rgba(255, 255, 255, 0.16);
  --swiper-scrollbar-drag-bg-color: #1f73c1;
  width: 90% !important;
  left: 50% !important;
  transform: translateX(-50%);
}

.prev-arrow,
.next-arrow {
  padding: 12px;
  z-index: 999;
  width: 16px;
  height: 16px;
}

.prev-arrow:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.04);
  border-radius: 8px;
}

.next-arrow:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.04);
  border-radius: 8px;
}

.next-arrow {
  position: absolute;
  right: 0;
  bottom: -10px;
}

.prev-arrow {
  position: absolute;
  left: 0;
  bottom: -10px;
}

.hover_div {
  display: none !important;
}
.swiper-carousel-card:hover .hover_div {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 320px;
  width: 240px;
  background-color: rgba(14, 52, 88, 0.9);
  border-radius: 16px;
  > img {
    width: fit-content;
    height: 40px;
    border-radius: 0;
  }
  > span {
    text-align: center;
    /* web/body/bold/text2 */
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
  }
}
.swiper-carousel-card:hover .standard_img {
  opacity: 0.1;
  position: absolute;
}
