@import "../../styles/variables";

.stats {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  max-width: 1280px;
  width: 90%;
  scroll-margin: 100px;
  &_title {
    color: $color-main-white;
    text-align: center;

    /* web/title/title4 */
    font-family: "Open Sans";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 123.077% */
  }

  &_subtext {
    color: var(--brand-90, #d3e6f8);
    text-align: center;

    /* web/body/bold/text2 */
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }

  &_tiles {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    width: 100%;
    @media screen and (max-width: $breakpoint) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      margin: 0 auto;
    }
  }
}

.stat-tile {
  display: flex;
  padding: 40px 24px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  background: var(--brand-14, #0a253d);

  @media screen and (max-width: $breakpoint) {
    padding: 24px;
  }

  &_icon {
    > img {
      height: 32px;
    }
  }

  &_number {
    display: flex;
    height: 70.12px;
    flex-direction: row;
    justify-content: center;
    color: $color-main-white;
    text-align: center;

    /* web/title/title1 */
    font-family: "Open Sans";
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px; /* 108.333% */
  }

  &_representative {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $color-main-white;
    text-align: center;

    /* web/body/regular/text2 */
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    opacity: 0.7;
  }
}
