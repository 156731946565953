@import "../../styles/variables";
.landing {
  @media screen and (max-width: $breakpoint) {
    margin-top: -270px;
  }
  position: relative;
  width: 100%;
  height: 1080px;
  margin-top: -300px;
  scroll-margin: 100px;
  &_download {
    @media screen and (max-width: $breakpoint) {
      bottom: 75px;
    }
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    bottom: 50px;
    gap: 12px;
    width: 100%;
    z-index: 999;
    &-title {
      color: $color-main-white;
      text-align: center;

      /* web/title/title4 */
      font-family: "Open Sans";
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px; /* 123.077% */
    }
    &-count {
      color: var(--brand-90, #d3e6f8);
      text-align: center;

      /* web/body/bold/text2 */
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
    }
    &-buttons {
      @media screen and (max-width: $breakpoint) {
        flex-direction: column;
        width: 90%;
        margin: 0 auto;
        align-items: center;
      }
      display: flex;
      align-items: flex-start;
      gap: 16px;
      padding: 24px 0;
      > * {
        cursor: pointer;
      }
    }
  }
  &_circle {
    position: absolute;
    width: 100%;
    height: 563.032px;
    border-radius: 629.641px;
    background: radial-gradient(
      38.23% 38.23% at 50% 50%,
      rgba(31, 115, 193, 0.48) 13.37%,
      rgba(31, 115, 193, 0) 100%
    );
    mix-blend-mode: screen;
  }
  &_blur {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 563.032px;
    background: linear-gradient(
      180deg,
      rgba(217, 217, 217, 0) 10.5%,
      #061523 50%
    );
  }
}
