@import "../../styles/variables";

.features {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 1280px;
  gap: 48px;
  scroll-margin: 100px;
  &_exclusive {
    color: $color-main-white;
    text-align: center;

    /* web/title/title4 */
    font-family: "Open Sans";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 123.077% */
  }
  &_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }
}

.feature-content {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  &_image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
  &_item {
    @media screen and (max-width: $breakpoint) {
      flex-direction: column;
      height: auto;
      width: 95%;
      margin: 0 auto;
      align-items: center;
    }
    z-index: 1;
    display: flex;
    width: 100%;
    max-width: 1280px;
    height: auto;
    justify-content: space-between;
    flex-shrink: 0;
    border-radius: 16px;
    background: linear-gradient(
        180deg,
        rgba(31, 115, 193, 0) 0%,
        rgba(31, 115, 193, 0.12) 100%
      ),
      rgba(255, 255, 255, 0.04);
    &_sub {
      @media screen and (max-width: $breakpoint) {
        padding: 16px;
        text-align: center;
        width: 75%;
      }
      @media screen and (max-width: $breakpoint-xl) {
        padding: 40px;
      }
      display: flex;
      padding: 80px;
      flex-direction: column;
      align-items: flex-start;
      gap: 24px;
      flex: 1 0 0;
      z-index: 1;
      max-width: 600px;
      width: 100%;
      &_icon {
        @media screen and (max-width: $breakpoint) {
          width: 100%;
        }
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 12px;
        > img {
          width: 32px;
          height: 32px;
        }
      }
      &_title {
        @media screen and (max-width: $breakpoint) {
          color: #fff;
          text-align: center;
          font-size: 18px;
          line-height: 28px; /* 155.556% */
        }
        color: $color-main-white;

        /* web/title/title4 */
        font-family: "Open Sans";
        font-size: 26px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px; /* 123.077% */
      }
      &_description {
        @media screen and (max-width: $breakpoint) {
          width: 100%;
        }
        color: $color-main-white;

        /* web/body/regular/text2 */
        font-family: "Open Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 150% */
        opacity: 0.7;
      }
      &_list {
        @media screen and (max-width: $breakpoint) {
          width: 100%;
        }
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        align-self: stretch;
      }
    }
    &_image {
      display: flex;
      align-items: flex-end;
      max-width: 700px;
      padding: 24px 24px 0;
      & > img {
        width: 100%;
        height: 100%;
      }
      @media screen and (max-width: $breakpoint-xl) {
        max-width: 400px;
        & > img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
  &_list-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
    @media screen and (max-width: $breakpoint) {
      width: 100%;
      align-items: flex-start;
    }
    &_title {
      @media screen and (max-width: $breakpoint) {
        width: 100%;
        text-align: left;
      }
      > img {
        width: 12px;
        height: 12px;
        margin-top: 6px;
      }
      display: flex;
      flex-direction: row;
      gap: 10px;
      flex: 1 0 0;
      color: $color-main-white;

      /* web/body/bold/text2 */
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
    }
    &_sub {
      @media screen and (max-width: $breakpoint) {
        width: 75%;
        text-align: left;
        padding: 0 24px 0 0;
        margin-left: 1.4rem;
      }
      font-family: "Open Sans";
      flex: 1 0 0;
      display: flex;
      padding: 0px 24px;
      align-items: center;
      gap: 4px;
      align-self: stretch;
      opacity: 0.7;
    }
  }
}

.row-reverse {
  display: flex;
  flex-direction: row-reverse !important;
  @media screen and (max-width: $breakpoint) {
    flex-direction: column !important;
  }
}
