@import "../../styles/variables";

.reviews {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 90%;
  max-width: 1280px;
  margin: 0 auto;
  scroll-margin: 100px;
  &_title {
    color: #fff;
    text-align: center;

    /* web/title/title4 */
    font-family: "Open Sans";
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 123.077% */
  }
  &_tiles {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 12px;
    width: 100%;
    @media screen and (max-width: $breakpoint) {
      margin: 0 auto;
      grid-template-columns: 1fr;
    }
  }
  &_all {
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    padding: 12px;
    border-radius: 8px;
    &:hover {
      background: rgba(255, 255, 255, 0.04);
      cursor: pointer;
    }
  }
}

.review-card {
  display: flex;
  padding: 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  height: 250px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.04);

  &_rating {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    &-total {
      color: $color-main-white;
      font-family: "Open Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      &-modal {
        color: $color-main-white;
        font-family: "Open Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
      }
    }
  }

  &_comment {
    align-self: stretch;
    color: $color-main-white;

    /* web/body/regular/text2 */
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    &_read-more {
      color: rgba(167, 205, 241, 1);
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &_author {
    color: $color-main-white;

    /* web/body/regular/text3 */
    font-family: "Open Sans";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 146.667% */

    opacity: 0.7;
  }
}

.modal-content {
  @media screen and (max-width: $breakpoint) {
    min-width: 100%;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-width: 472px;
  width: 100%;
  &-comment {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: $color-main-white;
    width: 100%;
  }
  &-button {
    max-width: 50px;
    width: 100%;
    color: $color-main-white;
    &:hover {
      color: $color-main-white-hover !important;
    }
  }
  &-progress {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 32px;
    color: $color-main-white;
  }
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid $color-main-white;
  display: inline-block;
}

.dot.active {
  background-color: #717171;
}

.ant-modal-content {
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.04) !important;
  padding: 20px 24px 64px 24px !important;
}

.ant-modal-wrap {
  background: $color-background-primary !important;
}

.ant-modal-close {
  color: $color-main-white !important;
}

.ant-rate-star-zero {
  color: #f2f2f2;
}
