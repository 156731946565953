$color-background-primary: #061523;
$color-stat: #3bb0d4;
$color-main-background: #001538;
$color-main-liquid: #001538;
$color-main-white: #f2f2f2;
$color-main-white-hover: #f2f2f299;
$color-main-campaign-menu: #000f29;
$color-ant-components: #021e4a;
$color-carousel-border: #0e3266;
$color-main-background-linear: linear-gradient(
  0deg,
  #001538 77.92%,
  #0e3266 100%
);
$color-campaign-card-background: rgba(255, 255, 255, 0.08);
$color-talent-stat-card-title: #bebeff;
$color-action-card-title: #3bb0d4;
$color-main-black: #0f0f0f;
$breakpoint: 769px;
$breakpoint-xl: 1200px;
