@import "styles/variables";
@import "styles/fonts";

:root {
  overscroll-behavior: none;
}

body {
  margin: 0;
  padding: 0;
}

.app {
  @media screen and (max-width: $breakpoint) {
    gap: 48px;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  height: 100%;
  background-color: $color-background-primary;
  color: $color-main-white;
  gap: 120px;
  overscroll-behavior: none;
}
