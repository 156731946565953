@import "../../styles/variables";

.footer {
  @media screen and (max-width: $breakpoint) {
    padding: 0;
    margin-top: 64px;
    padding-bottom: 24px;
  }
  display: flex;
  width: 90%;
  max-width: 1254px;
  padding: 40px 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  scroll-margin: 100px;

  &_download {
    @media screen and (max-width: $breakpoint) {
      flex-direction: column;
      margin-bottom: 0;
      margin-top: -64px;
      align-items: flex-start;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    margin-top: -96px;
    margin-bottom: 120px;
    width: 100%;
    &-title {
      color: $color-main-white;
      text-align: center;

      /* web/title/title4 */
      font-family: "Open Sans";
      font-size: 26px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px; /* 123.077% */
    }
    &-count {
      color: var(--brand-90, #d3e6f8);
      text-align: center;

      /* web/body/bold/text2 */
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
    }
    &-buttons {
      @media screen and (max-width: $breakpoint) {
        flex-direction: column;
        align-items: flex-start;
      }
      > * {
        cursor: pointer;
      }
      display: flex;
      align-items: flex-start;
      gap: 16px;
    }
  }

  &_top {
    @media screen and (max-width: $breakpoint) {
      flex-direction: column;
      gap: 24px;
    }
    display: flex;
    width: 100%;
    justify-content: space-between;
    &_left {
      @media screen and (max-width: $breakpoint) {
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
      }
      display: flex;
      align-items: center;
      gap: 24px;
      color: $color-main-white;

      /* web/body/regular/text2 */
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      > div {
        padding: 12px;
        &:hover {
          background-color: rgba(255, 255, 255, 0.04);
          border-radius: 8px;
          cursor: pointer;
        }
      }
    }
    &_right {
      display: flex;
      gap: 12px;
      > * {
        padding: 12px;
        font-size: 1.5rem;
        &:hover {
          background-color: rgba(255, 255, 255, 0.04);
          border-radius: 8px;
          cursor: pointer;
        }
      }
    }
  }

  &_line {
    background: rgba(255, 255, 255, 0.16);
    width: 100%;
    height: 1px;
  }

  &_bottom {
    color: $color-main-white;
    text-align: center;

    /* web/body/regular/text2 */
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
}
