@import "../../styles/variables";

.header {
  display: flex;
  padding: 16px 0;
  justify-content: center;
  align-items: center;
  gap: 40px;
  background: #061523;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1000;
  @media screen and (max-width: $breakpoint) {
    padding: 8px 0;
  }
  &_logo {
    cursor: pointer;
    @media screen and (max-width: $breakpoint) {
      margin: auto;
      padding-right: 96px;
    }
  }
  &_nav {
    display: flex;
    align-items: center;
    gap: 40px;
    @media screen and (max-width: $breakpoint) {
      display: none;
    }
    &_item {
      display: flex;
      padding: 12px;
      align-items: center;
      gap: 4px;
      cursor: pointer;
      width: fit-content;
      /* web/body/bold/text2 */
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 150% */
      &:hover {
        background-color: rgba(255, 255, 255, 0.04);
        border-radius: 8px;
      }
      &:active {
        background-color: rgba(255, 255, 255, 0.08);
        border-radius: 8px;
      }
    }
  }
  &_menu {
    display: none;
    @media screen and (max-width: $breakpoint) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px;
    }
  }
  &_mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 24px;
    &-download {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
      margin-top: 75px;
      > * {
        cursor: pointer;
      }
    }
  }
}

.ant-drawer-content {
  background: #071625 !important;
  color: $color-main-white;
}

.ant-drawer-close {
  color: $color-main-white !important;
  font-size: 24px !important;
}

.ant-drawer-header {
  padding: 18px 13px !important;
}
.ant-drawer-body {
  padding: 24px 16px !important;
}
